
import { message } from 'ant-design-vue'
import { computed, defineComponent } from 'vue'
import { NavigationGuard, NavigationGuardWithThis, useRoute } from 'vue-router'

import SurveyStatusList from '@/components/surveyStatus/SurveyUserStatusList.vue'
import { MESSAGE } from '@/constants/message'
import { PATH } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { AllocationActionEnum } from '@/store/enums/actions/allocation'
import { SurveyActionEnum } from '@/store/enums/actions/survey'
import { SurveyStatusActionEnum } from '@/store/enums/actions/surveyStatus'
import { UserActionEnum } from '@/store/enums/actions/user'
import { vuexActions } from '@/store/util'
import { Allocation } from '@/types'

const loadPage: NavigationGuardWithThis<undefined> | NavigationGuard = async (to, from, next) => {
  const { surveyId, workspaceId } = to.params
  if (!surveyId || Array.isArray(surveyId)) {
    message.error({
      content: MESSAGE.UNEXPECTED_SURVEY_ID({ surveyId }),
      duration: 3
    })
    setTimeout(() => {
      next(PATH.HOME)
    }, 3000)
    return
  }
  if (!workspaceId || Array.isArray(workspaceId)) {
    message.error({
      content: MESSAGE.UNEXPECTED_WORKSPACE_ID(workspaceId),
      duration: 3
    })
    setTimeout(() => {
      next(PATH.HOME)
    }, 3000)
    return
  }

  const store = useStore()
  await store.dispatch(vuexActions(ModuleNames.SURVEY, SurveyActionEnum.GET_SURVEY), surveyId)
  const allocations = (
    await store.dispatch(
      vuexActions(ModuleNames.ALLOCATION, AllocationActionEnum.GET_ALLOCATIONS),
      workspaceId
    )
  ).content
  const userIds = allocations.map(({ userId }: { userId: Pick<Allocation, 'userId'> }) => userId)
  await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.GET_USERS), {
    params: { id: userIds },
    getAll: true
  })
  await store.dispatch(
    vuexActions(ModuleNames.SURVEY_STATUS, SurveyStatusActionEnum.GET_SURVEY_STATUSES),
    { surveyId, getAll: true }
  )
  next()
}

export default defineComponent({
  components: {
    SurveyStatusList
  },
  beforeRouteEnter: loadPage as NavigationGuard,
  beforeRouteUpdate: loadPage as NavigationGuardWithThis<undefined>,
  setup() {
    const route = useRoute()
    const surveyId = computed(() => {
      if (typeof route.params.surveyId === 'string') {
        return route.params.surveyId
      }
      return null
    })

    const workspaceId = computed(() => {
      if (typeof route.params.workspaceId === 'string') {
        return route.params.workspaceId
      }
      return null
    })
    return {
      surveyId,
      workspaceId
    }
  }
})
