
import { MailOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import moment from 'moment'
import { computed, defineComponent, ref } from 'vue'

import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { MESSAGE } from '@/constants/message'
import { PATH } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { SurveyStatusActionEnum } from '@/store/enums/actions/surveyStatus'
import { SurveyStatusStateEnum } from '@/store/enums/states/surveyStatus'
import { UserStateEnum } from '@/store/enums/states/user'
import { vuexActions } from '@/store/util'
import { SurveySchema, SurveyStatus, User } from '@/types'

const tableColumns = [
  {
    dataIndex: 'username',
    key: DB_FIELDS.SURVEY_STATUS.USER_ID,
    title: 'Username'
  },
  {
    title: 'Status',
    dataIndex: DB_FIELDS.SURVEY_STATUS.STATUS,
    key: DB_FIELDS.SURVEY_STATUS.STATUS
  },
  {
    title: 'Send On',
    dataIndex: DB_FIELDS.SURVEY_STATUS.SENT_ON,
    key: DB_FIELDS.SURVEY_STATUS.SENT_ON,
    customRender: ({ text }: { text: string }) => {
      const sendOn = moment(text)
      if (sendOn.isValid()) {
        return sendOn.format('DD/MM/YYYY HH:mm:ss')
      }
      return ''
    }
  },
  {
    title: 'Completion Rate',
    key: DB_FIELDS.SURVEY_STATUS.COMPLETION_RATE,
    dataIndex: DB_FIELDS.SURVEY_STATUS.COMPLETION_RATE,
    customRender: ({ text }: { text: number }) => `${text * 100}%`
  },
  {
    title: 'Action',
    key: 'action',
    slots: { customRender: 'action' }
  }
]

export default defineComponent({
  components: {
    MailOutlined
  },
  props: {
    workspaceId: { type: String, required: true },
    surveyId: { type: String, required: true }
  },
  setup(props) {
    const store = useStore()

    const users = computed(() => store.state[ModuleNames.USER][UserStateEnum.USER_LIST]?.content)
    const dataSource = computed(() => {
      const surveyStatuses =
        store.state[ModuleNames.SURVEY_STATUS][SurveyStatusStateEnum.SURVEY_STATUS_LIST]?.content
      return surveyStatuses?.map((each: SurveyStatus & { [key: string]: string }) => ({
        ...each,
        key: each?.id,
        url: each?.ext?.surveyUrlWithSurveyKey || undefined,
        username: users.value.find((user: User) => user.id === each.userId)?.username
      }))
    })
    const selectedRowsRef = ref<Array<SurveyStatus>>([])
    const selectedRowsKeysRef = ref<Array<string | number>>([])
    const isSendingEmailModalVisible = ref<boolean>(false)
    const sendingProgress = ref<number>(0)

    const rowSelection = {
      selectedRowKeys: selectedRowsKeysRef,
      onChange: (selectedRowKeys: (string | number)[], selectedRows: Array<SurveyStatus>) => {
        selectedRowsRef.value = selectedRows
        selectedRowsKeysRef.value = selectedRows.map((each) => each.id)
      },
      onSelect: (record: SurveyStatus, selected: boolean, selectedRows: Array<SurveyStatus>) => {
        selectedRowsRef.value = selectedRows
        selectedRowsKeysRef.value = selectedRows.map((each) => each.id)
      },
      onSelectAll: (selected: boolean, selectedRows: Array<SurveyStatus>) => {
        selectedRowsRef.value = selectedRows
        selectedRowsKeysRef.value = selectedRows.map((each) => each.id)
      }
    }

    /**
     * Toggle email sending modal
     **/
    const toggleEmailSendingModal = () => {
      isSendingEmailModalVisible.value = !isSendingEmailModalVisible.value
    }

    /**
     * Generate survey link
     **/
    const generateSurveyUrl = (surveyId: SurveySchema['id']) => {
      if (surveyId && props.workspaceId) {
        const paramsObj: Record<string, any> = {
          surveyId,
          workspaceId: props.workspaceId
        }
        const matches = PATH.SURVEY.match(/:[a-zA-Z]+(?=\/)?/g)
        let result = PATH.SURVEY
        if (matches && matches.length > 0) {
          matches.forEach((each) => {
            const varName = each.replace(':', '')
            if (Object.prototype.hasOwnProperty.call(paramsObj, varName)) {
              result = result.replace(each, paramsObj[varName])
            }
          })
        }
        return `${window.location.origin}/#/go/` + result
      }
      return null
    }

    /**
     * Send survey to each user
     * @param record
     */
    const sendSurvey = async (record: SurveyStatus) => {
      const { id, surveyId } = record
      try {
        const surveyUrl = generateSurveyUrl(surveyId)
        if (surveyUrl) {
          isSendingEmailModalVisible.value = true
          sendingProgress.value = 0
          await store.dispatch(
            vuexActions(ModuleNames.SURVEY_STATUS, SurveyStatusActionEnum.DISTRIBUTE_SURVEY),
            {
              id,
              surveyUrl,
              surveyUrlVariableName: 'surveyUrl',
              userNameVariableName: 'userName',
              surveyNameVariableName: 'surveyName'
            }
          )
          sendingProgress.value = 100
        }
        message.success(MESSAGE.SEND_SURVEY_SUCCESS)
        isSendingEmailModalVisible.value = false
      } catch (err) {
        message.error(MESSAGE.SEND_SURVEY_FAIL)
        throw err
      }
    }

    /**
     * Bulk send survey to each user
     */
    const bulkSendSurvey = async () => {
      if (selectedRowsRef.value.length > 0) {
        isSendingEmailModalVisible.value = true
        sendingProgress.value = 0
        for (let i = 0; i < selectedRowsRef.value.length; i++) {
          const { id, surveyId, status } = selectedRowsRef.value[i]
          if (status !== DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.COMPLETED) {
            try {
              const surveyUrl = generateSurveyUrl(surveyId)
              if (surveyUrl) {
                await store.dispatch(
                  vuexActions(ModuleNames.SURVEY_STATUS, SurveyStatusActionEnum.DISTRIBUTE_SURVEY),
                  {
                    id,
                    surveyUrl,
                    surveyUrlVariableName: 'surveyUrl',
                    userNameVariableName: 'userName',
                    surveyNameVariableName: 'surveyName'
                  }
                )
                sendingProgress.value = ((i + 1) / selectedRowsRef.value.length) * 100
              }
            } catch (err) {
              message.error(MESSAGE.SEND_SURVEY_FAIL)
              throw err
            }
          }
        }
        message.success(MESSAGE.SEND_SURVEY_SUCCESS)
        selectedRowsRef.value = []
        selectedRowsKeysRef.value = []
      }
      return
    }

    const copyToClipboard = (text: string) => {
      navigator.clipboard.writeText(text)
    }

    return {
      DB_ENUM_VALUES,
      dataSource,
      sendSurvey,
      bulkSendSurvey,
      copyToClipboard,
      toggleEmailSendingModal,
      tableColumns,
      users,
      rowSelection,
      selectedRowsRef,
      selectedRowsKeysRef,
      isSendingEmailModalVisible,
      sendingProgress
    }
  }
})
