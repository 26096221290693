import { render } from "./SurveyStatusListView.vue?vue&type=template&id=2daff029"
import script from "./SurveyStatusListView.vue?vue&type=script&lang=ts"
export * from "./SurveyStatusListView.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2daff029"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2daff029', script)) {
    api.reload('2daff029', script)
  }
  
  module.hot.accept("./SurveyStatusListView.vue?vue&type=template&id=2daff029", () => {
    api.rerender('2daff029', render)
  })

}

script.__file = "src/views/SurveyStatusListView.vue"

export default script